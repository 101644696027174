body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'genesys';
  src: url("/src/fonts/genesys.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

i.symbol {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'genesys' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.symbol {
  vertical-align: middle;
  font-size: 1.4rem;
}

.symbol-border {
  -webkit-text-stroke-color: black;
  -webkit-text-stroke-width: 0.1rem;
}

.d6 {
  letter-spacing: 0;
}

.ability-color{color:#41ad49;}
.boost-color{color:#72cddc;}
.challenge-color{color:#7d1721;}
.difficulty-color{color:#6c2b83;}
.proficiency-color{color:#fde700;}
.setback-color{color:#000;}

.advantage:before {
  content: "\e937"; }

.despair:before {
  content: "\e938"; }

.failure:before {
  content: "\e939"; }

.success:before {
  content: "\e93a"; }

.threat:before {
  content: "\e93b"; }

.triumph:before {
  content: "\e93c"; }

.d6:before {
  content: "\e93d"; }

.d6-outline:before {
  content: "\e93e"; }

.d8:before {
  content: "\e93f"; }

.d8-outline:before {
  content: "\e940"; }

.d12:before {
  content: "\e941"; }

.d12-outline:before {
  content: "\e942"; }

.combat:before {
  content: "\e943"; }

.social:before {
  content: "\e944"; }

.general:before {
  content: "\e945"; }